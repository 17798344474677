import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import logger from './logger'
import mitt from 'mitt'

// Vuetify
import vuetify from './plugins/vuetify'
import { loadFonts } from './plugins/webfontloader'

loadFonts()

const emitter = mitt()

const app = createApp(App)
    .use(vuetify, {
        theme: {
            primary: '#3f51b5',
            secondary: '#6889ff',
            accent: '#6889ff',
            error: '#f44336',
            warning: '#ffa000',
            info: '#2196f3',
            success: '#4caf50',
        },
    })
    .use(logger)
    .use(router)
    .use(store)

// Global event bus for the mixin 'jb-custom-activity' to receive calls from any component (Vue 2 global event bus is deprecated)
app.config.globalProperties.emitter = emitter
app.mount('#app')
