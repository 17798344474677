<template>
  <v-form
      v-model="isFormValid"
      ref="formPageMain"
      class="pa-2">
    <v-snackbar
      v-model="snackbar1"
      :timeout="timeout"
      color="red"
      location="top"
      :style="{'margin-top':'0px'}"
      multi-line>
      {{ snackbarText1 }}
      <template v-slot:actions>
        <v-btn
          color="white"
          variant="text"
          @click="snackbar1 = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
    <v-snackbar
      v-model="snackbar2"
      :timeout="timeout"
      color="red"
      location="top"
      :style="{'margin-top':'80px'}"
      multi-line>
      {{ snackbarText2 }}
      <template v-slot:actions>
        <v-btn
          color="white"
          variant="text"
          @click="snackbar2 = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
    <v-row justify="center">
      <v-col>
        <v-card>
          <v-toolbar
            color="blue"
            density="compact" >
            <v-toolbar-title>Configuration</v-toolbar-title>
          </v-toolbar>
          <v-container >
            <!-- Activity Name -->
            <v-row align="center" class="pa-0">
              <v-col cols="8" class="pa-0">
                <v-text-field
                    v-model="activityName"
                    dense
                    filled
                    label="Activity Name"
                    required
                    counter="250"
                    :rules="[rules.required, rules.length250]" >
                </v-text-field>
              </v-col>
              <v-col cols="4" class="pa-1">
                <v-checkbox
                    label="Cross Brand"
                    v-model="isCrossBrand">
                </v-checkbox>
              </v-col>
            </v-row>
            <!-- Brand and product -->
            <v-row align="center" class="pa-0">
              <v-col cols="4" class="pa-1">
                <v-autocomplete
                    v-model="brand"
                    :items="brandItems"
                    dense
                    filled
                    label="Brand"
                    placeholder="Start typing to Search"
                    required
                    :rules="[rules.required, rules.length100]" >
                </v-autocomplete>
              </v-col>
              <v-col cols="4" class="pa-1">
                <v-autocomplete
                    v-model="brandProduct"
                    :items="brandProductItems"
                    dense
                    filled
                    label="Brand Product"
                    placeholder="Start typing to Search"
                    required
                    :rules="[rules.required, rules.length100]">
                </v-autocomplete>
              </v-col>
              <v-col cols="4" class="pa-1">
                <v-autocomplete
                    v-model="deliverySponsor"
                    :items="deliverySponsorItems"
                    dense
                    filled
                    label="Delivery Sponsor"
                    placeholder="Start typing to Search"
                    required
                    :rules="[rules.required, rules.length100]">
                </v-autocomplete>
              </v-col>
            </v-row>
            <!-- Campaign Objective -->
            <v-row align="center" class="pa-0">
              <v-col cols="8" class="pa-1">
                <v-text-field
                    v-model="campaignObjective"
                    dense
                    filled
                    label="Campaign Objective"
                    required
                    counter="250"
                    :rules="[rules.required, rules.length250]" >
                </v-text-field>
              </v-col>
              <v-col cols="4" class="pa-1">
                <v-checkbox
                    label="Test"
                    v-model="isTest">
                </v-checkbox>
              </v-col>
            </v-row>
            <!-- Country, ISP and Short Code -->
            <v-row align="center" class="pa-0">
              <v-col cols="4" class="pa-1">
                <v-autocomplete
                    v-model="country"
                    :items="countryItems"
                    dense
                    filled
                    label="Country"
                    placeholder="Start typing to Search"
                    required
                    :rules="[rules.required, rules.length100]" >
                </v-autocomplete>
              </v-col>
              <v-col cols="4" class="pa-1">
                <v-autocomplete
                    v-model="isp"
                    :items="ispItems"
                    dense
                    filled
                    label="ISP"
                    placeholder="Start typing to Search"
                    required
                    :rules="[rules.required, rules.length100]">
                </v-autocomplete>
              </v-col>
              <v-col cols="4" class="pa-1">
                <v-autocomplete
                    v-model="shortCode"
                    :items="shortCodeItems"
                    dense
                    filled
                    label="Short Code"
                    placeholder="Start typing to Search"
                    required
                    :rules="[rules.required, rules.length100]">
                </v-autocomplete>
              </v-col>
            </v-row>
            <!-- Destination phone and Consent type-->
            <v-row align="center">
              <v-col cols="6" class="pa-1">
                <v-autocomplete
                    prepend-inner-icon="mdi-cellphone"
                    v-model="destination"
                    :items="destinationItems"
                    item-title="name"
                    item-value="expression"
                    dense
                    filled
                    label="Destination Phone"
                    placeholder="Start typing to Search"
                    required
                    :rules="[rules.required]">
                </v-autocomplete>
              </v-col>
              <v-col cols="6" class="pa-1">
                <v-autocomplete
                    v-model="consentType"
                    :items="consentTypeItems"
                    dense
                    filled
                    label="Consent Type"
                    placeholder="Start typing to Search"
                    required
                    :rules="[rules.required, rules.length100]">
                </v-autocomplete>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col>
        <v-card>
          <v-toolbar
              color="blue"
              density="compact">
            <v-toolbar-title>Message</v-toolbar-title>
          </v-toolbar>
          <v-container >
            <!-- Message Area -->
            <v-row align="center">
              <v-col class="pa-1">
                <v-textarea id="sfmcTextAreaVue"
                            ref="textarea"
                            counter
                            prepend-inner-icon="mdi-comment"
                            label="Message Content"
                            rows="4"
                            v-model="content"
                            required
                            :rules="[rules.required]">
                </v-textarea>
              </v-col>
            </v-row>
            <!-- Variable Selector -->
            <v-row align="center">
              <v-col class="pa-1">
                <v-autocomplete
                  prepend-inner-icon="mdi-code-braces"
                  v-model="variableValue"
                  :items="variableItems"
                  item-title="name"
                  item-value="expression"
                  dense
                  filled
                  label="Insert Dynamic Value"
                  placeholder="Start typing to Search">
                  <template v-slot:append>
                    <v-btn
                        class="searchBtn"
                        height="auto"
                        text
                        v-on:click="copyVariable">
                      <v-icon>mdi-plus</v-icon>
                      Insert
                    </v-btn>
                  </template>
                </v-autocomplete>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col cols="3"></v-col>
      <v-col cols="2">
        <v-btn
            primary color="red"
            v-on:click="clickCancel">
          Cancel
        </v-btn>
      </v-col><v-col cols="2">
        <v-btn
            :loading="loading"
            primary color="blue"
            v-on:click="submitSave">
          Save
        </v-btn>
      </v-col>
      <v-col cols="3"></v-col>
    </v-row>
  </v-form>
</template>
<style>
  .textChange {
    font-size: 10px;
  }
  .v-data-table {
    table {
      tr {
        th, td {
          padding: 0 8px !important;
        }
      }
    }
  }
</style>
<script>
import helpers from '@/mixins/helpers'
import jbCustomActivityHTTP from '@/mixins/jb-custom-activity-http'

export default {
  name: 'PageMain',
  mixins: [helpers, jbCustomActivityHTTP],

  data: () => ({
    // Form state variables
    dialog: false,
    isFormValid: false,
    rules: {
      required: value => (!!value || value === false) || 'This field is mandatory.',
      length100: value => value.length < 100 || 'Max 100 characters.',
      length250: value => value.length < 250 || 'Max 250 characters.',
    },
    destinationItems: [],
    variableItems: [],
    variableValue: null,
    lists: null,
    mid: null,
    userId: null,
    midValues: null,
    loading: false,
    snackbar1: false,
    snackbarText1: '',
    snackbar2: false,
    snackbarText2: '',
    timeout: 10000,
  }),

  mounted () {
    this.loading = true
    setTimeout(this.loadDataVariableItems, 5000);
    setTimeout(this.loadDataPhoneFieldItems, 5000);
    setTimeout(this.loadDataMidValues, 5000);
  },

  computed: {
    accessToken () {
      return this.$store.state.jbTokens.fuel2token
    },

    brand: {
      get () {
        return this.$store.state.uiValues.brand
      },
      set (value) {
        this.$store.commit('updateFieldBrand', value)
      },
    },

    brandItems: function () {
      let brands = []

      if (this.$store.state.uiValues.Brands) {
        brands = this.$store.state.uiValues.Brands.map(a => a = a.value)
      }
      return brands
    },

    brandProduct: {
      get () {
        return this.$store.state.uiValues.brandProduct
      },
      set (value) {
        this.$store.commit('updateFieldBrandProduct', value)
      },
    },

    brandProductItems: function() {
      let brandProducts = []

      if (this.$store.state.uiValues.BrandProducts) {
        brandProducts = this.$store.state.uiValues.BrandProducts.map(a => a = a.value)
      }
      return brandProducts
    },


    consentType: {
      get () {
        return this.$store.state.uiValues.consentType
      },
      set (value) {
        this.$store.commit('updateFieldConsentType', value)
      },
    },

    consentTypeItems: function() {
      let consentTypes = []

      if (this.$store.state.uiValues.ConsentTypes) {
        consentTypes = this.$store.state.uiValues.ConsentTypes.map(a => a = a.value)
      }
      return consentTypes
    },


    deliverySponsor: {
      get () {
        return this.$store.state.uiValues.deliverySponsor
      },
      set (value) {
        this.$store.commit('updateFieldDeliverySponsor', value)
      },
    },

    deliverySponsorItems: function() {
      let deliverySponsors = []

      if (this.$store.state.uiValues.DeliverySponsors) {
        deliverySponsors = this.$store.state.uiValues.DeliverySponsors.map(a => a = a.value)
      }
      return deliverySponsors
    },

    activityName: {
      get () {
        return this.$store.state.uiValues.activityName
      },
      set (value) {
        this.$store.commit('updateFieldActivityName', value)
      },
    },

    isCrossBrand: {
      get () {
        return this.$store.state.uiValues.isCrossBrand
      },
      set (value) {
        this.$store.commit('updateFieldIsCrossBrand', value)
      },
    },

    campaignObjective: {
      get () {
        return this.$store.state.uiValues.campaignObjective
      },
      set (value) {
        this.$store.commit('updateFieldCampaignObjective', value)
      },
    },

    isTest: {
      get () {
        return this.$store.state.uiValues.isTest
      },
      set (value) {
        this.$store.commit('updateFieldIsTest', value)
      },
    },

    country: {
      get () {
        return this.$store.state.uiValues.country
      },
      set (value) {
        this.$store.commit('updateFieldCountry', value)
      },
    },

    countryItems: function () {
      let countries = []

      if (this.$store.state.uiValues.Countries) {
        countries = this.$store.state.uiValues.Countries.map(a => a = a.value)
      }
      return countries
    },

    isp: {
      get () {
        return this.$store.state.uiValues.isp
      },
      set (value) {
        this.$store.commit('updateFieldISP', value)
      },
    },

    ispItems: function () {
      let isps = []

      if (this.$store.state.uiValues.ISPs) {
        isps = this.$store.state.uiValues.ISPs.map(a => a = a.value)
      }
      return isps
    },

    shortCode: {
      get () {
        return this.$store.state.uiValues.shortCode
      },
      set (value) {
        this.$store.commit('updateFieldShortCode', value)
      },
    },

    shortCodeItems: function() {
      let shortCodes = []
      if (this.$store.state.uiValues.ShortCodes) {
        shortCodes = this.$store.state.uiValues.ShortCodes.map(a => a = a.value)
      }

      return shortCodes
    },

    destination: {
      get () {
        return this.$store.state.uiValues.destination
      },
      set (value) {
        this.$store.commit('updateFieldDestination', value)
      },
    },

    content: {
      get () {
        return this.$store.state.uiValues.content
      },
      set (value) {
        this.$store.commit('updateFieldContent', value)
      },
    },

    jbActivityKey: {
        get () {
            return this.$store.state.jbPayload.key || 'Undefined'
        }
    },

    jbEventDefinitionKey: {
      get () {
        return this.$store.state.uiValues.jbEventDefinitionKey
      },
      set (value) {
        this.$store.commit('updateFieldJbEventDefinitionKey', value)
      },
    },

    jbTriggerEventDefinition () {
      return this.$store.state.jbTriggerEventDefinition
    },

    jbSchema () {
      return this.$store.state.jbSchema
    },

    jbInteractionPayload () {
      return this.$store.state.jbInteractionPayload
    }
  },

  watch: {
  },

  methods: {
    calcMargin(i) {
      return (i*60) + 'px'
    },

    /**
     * @description Button Next action. Calls the mapping view passing the DE ExternalKey as path parameter.
     */
    clickCancel () {
      this.emitter.emit('destroy')
    },

    /**
     * @description Button Next action. Calls the mapping view passing the DE ExternalKey as path parameter.
     */
    submitSave () {
      this.$log.debug(`Submitting Form`)

      // Validate form and save state
      this.$refs.formPageMain.validate().then(r => {
        if (r.valid) {
          this.$log.debug('Form is valid', r)
          // Reset CA inArguments
          this.$store.commit('inArgumentsClear')

          // Generate inArgument for the DE Key
          const inArgumentContent = {
            key: 'content',
            value: this.content,
            dataType: 'Text',
            isNullable: false,
          }
          const inArgumentBrand = {
            key: 'brand',
            value: this.brand,
            dataType: 'Text',
            isNullable: false,
          }
          const inArgumentBrandProduct = {
            key: 'brandProduct',
            value: this.brandProduct,
            dataType: 'Text',
            isNullable: false,
          }
          const inArgumentIsCrossBrand = {
            key: 'isCrossBrand',
            value: this.isCrossBrand,
            dataType: 'Text',
            isNullable: false,
          }
          const inArgumentCampaignObjective = {
            key: 'campaignObjective',
            value: this.campaignObjective,
            dataType: 'Text',
            isNullable: false,
          }
          const inArgumentIsTest = {
            key: 'isTest',
            value: this.isTest,
            dataType: 'Text',
            isNullable: false,
          }
          const inArgumentConsentType = {
            key: 'consentType',
            value: this.consentType,
            dataType: 'Text',
            isNullable: false,
          }
          const inArgumentDeliverySponsor = {
            key: 'deliverySponsor',
            value: this.deliverySponsor,
            dataType: 'Text',
            isNullable: false,
          }
          const inArgumentCountry = {
            key: 'country',
            value: this.country,
            dataType: 'Text',
            isNullable: false,
          }
          const inArgumentISP = {
            key: 'isp',
            value: this.isp,
            dataType: 'Text',
            isNullable: false,
          }
          const inArgumentShortCode = {
            key: 'shortCode',
            value: this.shortCode,
            dataType: 'Text',
            isNullable: false,
          }
          const inArgumentActivityName = {
            key: 'activityName',
            value: this.activityName,
            dataType: 'Text',
            isNullable: false,
          }
          const inArgumentDestination = {
            key: 'destination',
            value: this.destination,
            dataType: 'Text',
            isNullable: false,
          }
          const inArgumentJbEventDefinitionKey = {
            key: 'jbEventDefinitionKey',
            value: this.jbTriggerEventDefinition?.eventDefinitionKey,
            dataType: 'Text',
            isNullable: false,
          }
          const inArgumentJbParentId = {
            key: 'jbParentId',
            value: this.jbInteractionPayload?.id,
            dataType: 'Text',
            isNullable: false,
          }
          const inArgumentJbName = {
            key: 'jbName',
            value: this.jbInteractionPayload?.name,
            dataType: 'Text',
            isNullable: false,
          }
          const inArgumentMid = {
            key: 'mid',
            value: this.mid,
            dataType: 'Text',
            isNullable: false,
          }
          const inArgumentUserId = {
            key: 'userId',
            value: this.userId,
            dataType: 'Text',
            isNullable: false,
          }

          this.$store.commit('inArgumentAdd', inArgumentContent)
          this.$store.commit('inArgumentAdd', inArgumentBrand)
          this.$store.commit('inArgumentAdd', inArgumentBrandProduct)
          this.$store.commit('inArgumentAdd', inArgumentConsentType)
          this.$store.commit('inArgumentAdd', inArgumentDeliverySponsor)
          this.$store.commit('inArgumentAdd', inArgumentActivityName)
          this.$store.commit('inArgumentAdd', inArgumentIsCrossBrand)
          this.$store.commit('inArgumentAdd', inArgumentCampaignObjective)
          this.$store.commit('inArgumentAdd', inArgumentIsTest)
          this.$store.commit('inArgumentAdd', inArgumentCountry)
          this.$store.commit('inArgumentAdd', inArgumentISP)
          this.$store.commit('inArgumentAdd', inArgumentShortCode)
          this.$store.commit('inArgumentAdd', inArgumentActivityName)
          this.$store.commit('inArgumentAdd', inArgumentDestination)
          this.$store.commit('inArgumentAdd', inArgumentJbEventDefinitionKey)
          this.$store.commit('inArgumentAdd', inArgumentJbParentId)
          this.$store.commit('inArgumentAdd', inArgumentJbName)
          this.$store.commit('inArgumentAdd', inArgumentMid)
          this.$store.commit('inArgumentAdd', inArgumentUserId)

          // Save Custom Activity State and Close
          this.emitter.emit('save')
        } else {
          this.$log.debug('Form is invalid', r)
        }
      }).catch(e => {
        this.$log.error('Validation failed', e)
      })
    },

    /**
     * Inserts the selected variable in the message body
     * @returns {Promise<void>}
     */
    copyVariable() {
      this.$log.info(`Copied variable ${this.variableValue}`);

      if (this.variableValue) {
        let cursorIndex = document.getElementById('sfmcTextAreaVue').selectionEnd || 0
        this.$log.info(`Message Cursor Position ${cursorIndex}`);1
        if (this.content && this.content.length > 0) {
          this.content = this.content.substring(0, cursorIndex)  + this.variableValue + this.content.substring(cursorIndex)
        } else {
          this.content = this.variableValue
        }

        document.getElementById('sfmcTextAreaVue').focus()
      }
    },

    /**
     * Get all input arguments from the journey schema and populate "variableItems"
     */
    loadDataVariableItems () {
      this.variableItems = [
        {
          key: 'ContactKey',
          expression: '{{Contact.Key}}',
          name: 'Journey Settings: Contact Key',
        },
        {
          key: 'Email',
          expression: '{{InteractionDefaults.Email}}',
          name: 'Journey Settings: Email Address',
        },
        {
          key: 'MobileNumber',
          expression: '{{InteractionDefaults.MobileNumber}}',
          name: 'Journey Settings: Mobile Number',
        },
      ]

      if (this.jbSchema.schema && this.jbSchema.schema.length > 0) {
        this.jbSchema.schema.forEach((schemaItem) => {
          // If the key contains spaces, the field name needs to be enclosed in double quotes
          if (schemaItem.key.indexOf(' ') > -1) {
            let fieldName = `"${schemaItem.key.substring(schemaItem.key.lastIndexOf('.') + 1)}"`
            schemaItem.key = `${schemaItem.key.substring(0, schemaItem.key.lastIndexOf('.') + 1)}${fieldName}`
          }

          // Now we prepare a combobox element, taking into account the possibility of having added double quotes
          const aField = {
            expression: `{{${schemaItem.key}}}`,
            key: schemaItem.key,
            name: `Data Extension: ${schemaItem.key.substring(schemaItem.key.lastIndexOf('.') + 1).replaceAll('"', '')}`,
          }
          this.variableItems.push(aField)
        })
      } else {
        this.snackbar1 = true
        this.snackbarText1 = 'ERROR. Taking too long to load the entry source data extension. Ensure that there is one set in the journey and reopen the activity configuration screen.'
      }
    },

    loadDataPhoneFieldItems () {
      if (this.jbSchema.schema && this.jbSchema.schema.length > 0) {
        this.jbSchema.schema.forEach((schemaItem) => {
          // If the key contains spaces, the field name needs to be enclosed in double quotes
          if (schemaItem.key.indexOf(' ') > -1) {
            let fieldName = `"${schemaItem.key.substring(schemaItem.key.lastIndexOf('.') + 1)}"`
            schemaItem.key = `${schemaItem.key.substring(0, schemaItem.key.lastIndexOf('.') + 1)}${fieldName}`
          }

          // Now we prepare a combobox element, taking into account the possibility of having added double quotes
          const aField = {
            expression: `{{${schemaItem.key}}}`,
            key: schemaItem.key,
            name: `Data Extension: ${schemaItem.key.substring(schemaItem.key.lastIndexOf('.') + 1).replaceAll('"', '')}`,
          }

          // Only if the field contains the keywords phone or mobile, we add it to this combobox
          if (aField.key.toLowerCase().indexOf('phone') > -1 || aField.key.toLowerCase().indexOf('mobile') > -1) {
            this.destinationItems.push(aField)
          }
        })
      }
    },

    loadDataMidValues () {
      this.mid = this.$store.state.jbTokens.MID
      this.userId = this.$store.state.jbTokens.UID
      this.$log.debug('Mounted - MID', this.mid)
      this.$log.debug(`Mounted - uivalues`, this.$store.state.uiValues)

      this.loading = false

      if (!this.mid || !this.userId) {
        this.snackbar2 = true
        this.snackbarText2 = 'ERROR. Taking too long to load the current user ID and MID. Close the activity configuration screen and try again.'
        this.loading = true
      }
    }
  },
}

</script>
