import { createRouter, createWebHistory } from 'vue-router'
import PageMain from '@/components/PageMain.vue'
import PageRunningModal from '@/components/PageRunningModal.vue'
import PageRunningHover from '@/components/PageRunningHover.vue'

const routes = [
    {
        path: '/running-modal',
        name: 'Running Modal Page',
        component: PageRunningModal,
    },
    {
        path: '/running-hover',
        name: 'Running Hover Page',
        component: PageRunningHover,
    },
    {
        path: '/',
        name: 'Main Page',
        component: PageMain,
    },
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});

export default router;