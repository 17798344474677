<template>
  <v-app id="ca">
    <v-main>
      <router-view></router-view>
    </v-main>
  </v-app>
</template>

<script>
import jbCustomActivityMixin from '@/mixins/jb-custom-activity'

export default {
  name: 'App',
  mixins: [jbCustomActivityMixin],
  data: () => ({
    clipped: false,
    drawer: false,
    miniVariant: false,
    right: true,
    rightDrawer: false,
    title: 'Custom Activity',
    isUnauthorized: false,
  }),
}
</script>
